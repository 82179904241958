import { Link } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <section className="relative container text-center py-16">
      <h1 className="text-4xl md:text-6xl tracking-tight mt-32">
        Page not found
      </h1>
      <p className="prose prose-xl mx-auto">
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        <br />
        <Link to="/" className="mb-32">
          Goto Home
        </Link>
      </p>
    </section>
  </Layout>
)

export default NotFoundPage
